<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle>
        <v-chip
          v-if="formattedItem.unidades"
          color="info"
          dark
          small
          label
          class="mr-1"
        >
          {{ formattedItem.unidades }} {{ 'unidad'|pluralize(formattedItem.unidades, 'es') }}
        </v-chip>
        <v-chip
          v-for="(accion, index) in formattedItem.accionesRealizadas"
          :key="`realizadas_${index}`"
          small
          label
          color="green"
          dark
          class="mt-1 ml-1"
        >
          {{ accion.cant_acciones }} {{ accion.desc_acciones }}
        </v-chip>
        <v-chip
          v-for="(accion, index) in formattedItem.accionesPendientes"
          :key="`pendientes_${index}`"
          small
          label
          color="red"
          dark
          class="mt-1 ml-1"
        >
          {{ accion.cant_acciones }} {{ accion.desc_acciones }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.subsis.descripcion
      item.subtitle = item.subsis.codigo
      item.unidades = item.cantidadMateriales
      return item
    }
  }
}
</script>
